import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import useApiRequest from "../../../../lib/hooks/useRequest";
import { CapacityCreationEntitiesResponseDto, FlatRate } from "../../../../lib/interfaces/organisation";
import { Button } from "../../../../ui/components/ui/button";
import DataFetchParent from "../../../../ui/components/ui/data-fetch";
import { Form } from "../../../../ui/components/ui/form";
import { createPaymentDataSchema, PersonalDataSchemaType } from "../../../organisations/root/form-types";
import PaymentFormItems from "../../../organisations/root/payment-form-items";
import { ErrorEntity, Error as ErrorI } from "../../../../components/root/context";

const PaymentFormFetcher = ({
  selectedPlan,
  setSelectedPlan,
}: {
  selectedPlan: FlatRate;
  setSelectedPlan: (plan: FlatRate) => void;
}) => {
  const { apiRequest } = useApiRequest();
  const [isLoading, setIsLoading] = useState(false);
  const [item, setItem] = useState<{
    personalData: PersonalDataSchemaType;
    startDateValues: CapacityCreationEntitiesResponseDto;
  }>();
  const navigate = useNavigate();

  const getStartDateValues = useCallback(async () => {
    const personalDataString = localStorage.getItem("selfCheckoutPersonalData");
    let personalData: PersonalDataSchemaType | undefined;
    if (!personalDataString) {
      navigate("/self-checkout/step1");
    } else {
      personalData = JSON.parse(personalDataString) as PersonalDataSchemaType;
    }
    setIsLoading(true);
    const res = await apiRequest<CapacityCreationEntitiesResponseDto>("self-checkout/capacity", "GET");
    if (res.data && personalData) {
      setItem({ personalData, startDateValues: res.data });
      setIsLoading(false);
    }
  }, [apiRequest, navigate]);

  useEffect(() => {
    getStartDateValues();
  }, [getStartDateValues]);

  return (
    <DataFetchParent
      isLoading={isLoading}
      data={item}
      renderElement={(values: {
        personalData: PersonalDataSchemaType;
        startDateValues: CapacityCreationEntitiesResponseDto;
      }) => (
        <PaymentForm
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          startDateValues={values.startDateValues}
          personalData={values.personalData}
        />
      )}
    />
  );
};

const PaymentForm = ({
  selectedPlan,
  setSelectedPlan,
  startDateValues,
  personalData,
}: {
  selectedPlan: FlatRate;
  setSelectedPlan: (plan: FlatRate) => void;
  startDateValues: CapacityCreationEntitiesResponseDto;
  personalData: PersonalDataSchemaType;
}) => {
  const navigate = useNavigate();
  const { apiRequest } = useApiRequest();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [ibanValid, setIbanValid] = useState<boolean | undefined>(undefined);
  const [bicValid, setBicValid] = useState<boolean | undefined>(undefined);
  const [lastValidatedIban, setLastValidatedIban] = useState<string>("");
  const [lastValidatedBic, setLastValidatedBic] = useState<string>("");
  const isGermany = personalData.country === "DE";

  const validateIban = async (iban: string, bic: string): Promise<{ iban: boolean; bic: boolean }> => {
    // Skip validation if values haven't changed
    if (iban === lastValidatedIban && bic === lastValidatedBic) {
      return { iban: ibanValid ?? false, bic: bicValid ?? false };
    }

    // Skip validation if already validating
    if (isValidating) {
      return { iban: false, bic: false };
    }

    setIsValidating(true);
    try {
      const response = await apiRequest(`self-checkout/iban-validation?iban=${iban}&bic=${bic}`, "GET");

      // Update last validated values
      setLastValidatedIban(iban);
      setLastValidatedBic(bic);

      if (response.status === 200) {
        setIbanValid(true);
        setBicValid(true);
        return { iban: true, bic: true };
      } else if (response.status === 400 && response.error) {
        const errorEntity = (response.error as ErrorI).entity;
        if (errorEntity === ErrorEntity.InvalidBicForbidden) {
          setBicValid(false);
          setIbanValid(true);
          return { iban: true, bic: false };
        } else if (errorEntity === ErrorEntity.InvalidIbanForbidden) {
          setIbanValid(false);
          setBicValid(true);
          return { iban: false, bic: true };
        } else if (errorEntity === ErrorEntity.InvalidIbanAndBicForbidden) {
          setIbanValid(false);
          setBicValid(false);
          return { iban: false, bic: false };
        }
        return { iban: false, bic: false };
      }
      // Handle any other response status
      setIbanValid(false);
      setBicValid(false);
      return { iban: false, bic: false };
    } catch (error) {
      // Handle any unexpected errors
      setIbanValid(false);
      setBicValid(false);
      return { iban: false, bic: false };
    } finally {
      setIsValidating(false);
    }
  };

  // Create payment schema with IBAN validation
  const paymentDataSchema = createPaymentDataSchema(validateIban, isGermany);
  type PaymentDataSchema = z.infer<typeof paymentDataSchema>;

  const form = useForm<PaymentDataSchema>({
    resolver: zodResolver(paymentDataSchema),
    mode: "onBlur",
    defaultValues: {
      iban: "",
      bic: "",
      invoiceMail: "",
      allowedJobsCount: selectedPlan === FlatRate.CLASSIC ? 1 : 2,
      newCustomerDate: "",
      termsOfService: false,
      paymentConfirmation: false,
      minimumDuration: false,
    },
  });

  // Trigger validation when form values change, except for IBAN and BIC which are validated on blur
  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      // Skip IBAN and BIC validation on change, they will be validated on blur
      if (name !== "iban" && name !== "bic") {
        form.trigger(name as keyof PaymentDataSchema);
      }
    });
    return () => subscription.unsubscribe();
  }, [form]);

  const onSubmit = async (data: PaymentDataSchema) => {
    try {
      setIsSubmitting(true);

      // Combine data from both steps
      const requestData = {
        ...personalData,
        ...data,
        invoiceMail: data.invoiceMail !== "" ? data.invoiceMail : undefined,
        bic: data.bic !== "" ? data.bic : undefined,
        iban: data.iban !== "" ? data.iban : undefined,
      };

      // Make API request
      const response = await apiRequest("self-checkout", "POST", {
        body: requestData,
      });

      if (response.status === 201) {
        // Clear localStorage
        localStorage.removeItem("selfCheckoutPersonalData");

        // Navigate to success page or dashboard
        navigate("/self-checkout/step3");
      } else {
        // Handle error
        console.error("Failed to submit form:", response.error);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const allowedJobsCount = selectedPlan === FlatRate.CLASSIC ? 1 : 2;
    form.setValue("allowedJobsCount", allowedJobsCount, { shouldValidate: true });
    form.setValue("newCustomerDate", "", { shouldValidate: true });
  }, [form, selectedPlan]);

  return (
    <div className="flex flex-col gap-8">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-5 h-full justify-around">
          <div className="flex flex-col gap-16">
            <div>
              <h3 className="text-2xl font-semibold">Zahlungsmethode</h3>
              <p className="text-gray-400 text-xs">Bitte trage Deine Zahlungsmethode unten ein.</p>
            </div>
            <PaymentFormItems
              form={form}
              isGermany={isGermany}
              startDateValues={startDateValues}
              isValidating={isValidating}
              ibanValid={ibanValid}
              bicValid={bicValid}
              setSelectedPlan={setSelectedPlan}
            />

            <div className="flex gap-3 flex-col">
              <Button type="submit" className="w-full" disabled={isSubmitting || !form.formState.isValid}>
                {isSubmitting ? "Wird gesendet..." : "Jetzt mit 7i7® verbindlich durchstarten"}
              </Button>
              <Button
                type="button"
                variant="outline"
                className="w-full"
                onClick={() => navigate("/self-checkout/step1")}
              >
                Zurück zu persönlichen Daten
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default PaymentFormFetcher;
