import { Path, UseFormReturn } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../../ui/components/ui/form";
import { Input } from "../../../ui/components/ui/input";
import { Checkbox } from "../../../ui/components/ui/checkbox";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../ui/components/ui/select";
import { PaymentDataSchemaType } from "./form-types";
import {
  CapacityBySlotsNeededResponseDto,
  CapacityCreationEntitiesResponseDto,
  FlatRate,
} from "../../../lib/interfaces/organisation";
import { formatDate } from "../../../lib/hooks/utils";
import { Loader2, CheckCircle2, XCircle } from "lucide-react";
import { Link } from "react-router-dom";

const PaymentFormItems = <T extends PaymentDataSchemaType>({
  form,
  startDateValues,
  isGermany = true,
  isValidating,
  ibanValid,
  bicValid,
  setSelectedPlan,
  isEditing = false,
}: {
  form: UseFormReturn<T>;
  isGermany?: boolean;
  startDateValues: CapacityCreationEntitiesResponseDto;
  isValidating: boolean;
  ibanValid: boolean | undefined;
  bicValid: boolean | undefined;
  setSelectedPlan: (plan: FlatRate) => void;
  isEditing?: boolean;
}) => {
  const flateRateValues = [
    {
      label: "7i7® flow - Classic Paket (1.850€/Monat)",
      value: FlatRate.CLASSIC,
    },
    {
      label: "7i7® flow - Premium Paket (3.700€/Monat)",
      value: FlatRate.PREMIUM,
    },
  ];

  return (
    <div className="flex flex-col gap-8">
      <div className="flex gap-3 flex-col">
        {isGermany ? (
          <>
            <div className="w-full relative">
              <FormField
                control={form.control}
                name={"iban" as Path<T>}
                render={({ field }) => (
                  <FormItem className="flex flex-col bg-white rounded-lg">
                    <FormControl>
                      <Input {...field} placeholder="IBAN *" value={field.value as string} />
                    </FormControl>
                    <div className="absolute right-3">
                      {isValidating && <Loader2 className="h-5 w-5 text-gray-400 animate-spin" />}
                      {!isValidating && ibanValid === true && <CheckCircle2 className="h-5 w-5 text-green-500" />}
                      {!isValidating && ibanValid === false && <XCircle className="h-5 w-5 text-red-500" />}
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="w-full relative">
              <FormField
                control={form.control}
                name={"bic" as Path<T>}
                render={({ field }) => (
                  <FormItem className="flex flex-col bg-white rounded-lg">
                    <FormControl>
                      <Input {...field} placeholder="BIC *" value={field.value as string} />
                    </FormControl>
                    <div className="absolute right-3">
                      {isValidating && <Loader2 className="h-5 w-5 text-gray-400 animate-spin" />}
                      {!isValidating && bicValid === true && <CheckCircle2 className="h-5 w-5 text-green-500" />}
                      {!isValidating && bicValid === false && <XCircle className="h-5 w-5 text-red-500" />}
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="w-full">
              <FormField
                control={form.control}
                name={"invoiceMail" as Path<T>}
                render={({ field }) => (
                  <FormItem className="flex flex-col bg-white rounded-lg">
                    <FormControl>
                      <Input
                        {...field}
                        placeholder="Alternative E-Mail für Rechnungen"
                        onChange={(e) => {
                          field.onChange(e);
                          form.trigger("invoiceMail" as Path<T>);
                        }}
                        value={field.value as string}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </>
        ) : (
          <div className="flex gap-2 items-center w-full">
            <div>
              <Input type="radio" checked={true} className="w-5 h-5 p-0" readOnly={true} />
            </div>
            <div className="flex flex-col">
              <h3 className="text-xl grow">Rechnung</h3>
              <p className="text-gray-400 text-sm">(7 Tage Zahlungsziel nach Rechnungseingang)</p>
            </div>
          </div>
        )}
      </div>
      <hr />
      <div className="flex flex-col gap-3">
        {!isEditing && (
          <div>
            <h3 className="text-2xl font-semibold">Flatrate</h3>
            <p className="text-gray-400 text-xs">Wähle Deinen Plan und das Startdatum aus.</p>
          </div>
        )}
        <div className="w-full flex flex-col gap-3">
          <FormField
            control={form.control}
            name={"allowedJobsCount" as Path<T>}
            render={({ field }) => (
              <FormItem className="flex flex-col bg-white rounded-lg">
                <FormControl>
                  <Select
                    onValueChange={(value) => {
                      const plan = value === "1" ? FlatRate.CLASSIC : FlatRate.PREMIUM;
                      setSelectedPlan(plan);
                      field.onChange(parseInt(value));
                      form.trigger("allowedJobsCount" as Path<T>);
                    }}
                    value={field.value?.toString() ?? ""}
                  >
                    <FormControl>
                      <SelectTrigger className="">
                        <SelectValue placeholder="Flatrate auswählen" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {flateRateValues.map((value, index) => (
                        <SelectItem key={value.value} value={index + 1 + ""}>
                          {value.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="w-full">
            <FormField
              control={form.control}
              name={"newCustomerDate" as Path<T>}
              render={({ field }) => {
                const currentCapacity = startDateValues.capacityBySlotsNeeded.find(
                  (value) => value.slotsNeeded === form.getValues("allowedJobsCount" as Path<T>)
                );

                return (
                  <FormItem className="flex flex-col bg-white rounded-lg">
                    <FormControl>
                      {currentCapacity && currentCapacity.capacity.length > 0 ? (
                        <Select
                          onValueChange={(value) => {
                            field.onChange(value);
                            form.trigger("newCustomerDate" as Path<T>);
                          }}
                          defaultValue={field.value as string}
                          value={field.value as string}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Startdatum auswählen *" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItems currentCapacity={currentCapacity} />
                          </SelectContent>
                        </Select>
                      ) : (
                        <div className="text-red-500">
                          <p>Keine freien Slots verfügbar.</p>
                        </div>
                      )}
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>
        </div>
      </div>
      {!isEditing && (
        <>
          <hr />
          <div className="flex gap-6 flex-col">
            <div className="flex gap-3 flex-col">
              <FormField
                control={form.control}
                name={"termsOfService" as Path<T>}
                render={({ field }) => (
                  <FormItem className="flex flex-col bg-white rounded-lg">
                    <div className="flex gap-2 items-center">
                      <FormControl>
                        <Checkbox
                          checked={field.value as boolean}
                          onCheckedChange={(e) => {
                            field.onChange(e);
                            form.trigger("termsOfService" as Path<T>);
                          }}
                        />
                      </FormControl>
                      <FormLabel className="text-xs text-[#888888]">
                        Hiermit stimme ich den{" "}
                        <Link to="https://www.7i7.de/agb" target="_blank" className="underline">
                          AGB
                        </Link>{" "}
                        und den{" "}
                        <Link to="https://www.7i7.de/datenschutz" target="_blank" className="underline">
                          Datenschutzbestimmungen
                        </Link>{" "}
                        zu. *
                      </FormLabel>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {isGermany && (
                <FormField
                  control={form.control}
                  name={"paymentConfirmation" as Path<T>}
                  render={({ field }) => (
                    <FormItem className="flex flex-col bg-white rounded-lg">
                      <div className="flex gap-2 items-center">
                        <FormControl>
                          <Checkbox
                            checked={field.value as boolean}
                            onCheckedChange={(e) => {
                              field.onChange(e);
                              form.trigger("paymentConfirmation" as Path<T>);
                            }}
                          />
                        </FormControl>
                        <FormLabel className="text-xs text-[#888888]">
                          Ich ermächtige 7i7®, Zahlungen von dem Konto mittels Lastschrift einzuziehen. *
                        </FormLabel>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              <FormField
                control={form.control}
                name={"minimumDuration" as Path<T>}
                render={({ field }) => (
                  <FormItem className="flex flex-col bg-white rounded-lg">
                    <div className="flex gap-2 items-center">
                      <FormControl>
                        <Checkbox
                          checked={field.value as boolean}
                          onCheckedChange={(e) => {
                            field.onChange(e);
                            form.trigger("minimumDuration" as Path<T>);
                          }}
                        />
                      </FormControl>
                      <FormLabel className="text-xs text-[#888888]">
                        Ich habe zur Kenntnis genommen, dass die Mindestlaufzeit{" "}
                        <span className="font-bold">4 Monate</span> beträgt. *
                      </FormLabel>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const SelectItems = ({ currentCapacity }: { currentCapacity: CapacityBySlotsNeededResponseDto }) => {
  return (
    <>
      {currentCapacity.capacity.map((value) => {
        const color = value.freeCapacitySlots < 7 ? (value.freeCapacitySlots < 4 ? "#CE0909" : "#FFBF00") : "#C0DE60";
        return (
          <SelectItem key={value.date} value={value.date}>
            <div className="flex items-center gap-1 text-sm">
              <div style={{ color }} className="w-2.5 h-2.5">
                <svg className="w-2.5 h-2.5" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <ellipse cx="50" cy="50" rx="50" ry="50" fill="currentColor" />
                </svg>
              </div>
              <span>
                {`${formatDate(value.date)} (${value.freeCapacitySlots} ${
                  value.freeCapacitySlots === 1 ? "freier Platz" : "freie Plätze"
                })`}
              </span>
            </div>
          </SelectItem>
        );
      })}
    </>
  );
};

export default PaymentFormItems;
