import { Path, UseFormReturn } from "react-hook-form";
import { FormControl, FormField, FormItem, FormMessage } from "../../../ui/components/ui/form";
import { Input } from "../../../ui/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../ui/components/ui/select";
import { PersonalDataSchemaType } from "./form-types";

const PersonalFormItems = <T extends PersonalDataSchemaType>({ form }: { form: UseFormReturn<T> }) => {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-3">
        <div className="flex gap-3">
          <div className="w-1/2">
            <FormField
              control={form.control}
              name={"firstName" as Path<T>}
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormControl>
                    <Input {...field} placeholder="Vorname *" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="w-1/2">
            <FormField
              control={form.control}
              name={"lastName" as Path<T>}
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormControl>
                    <Input {...field} placeholder="Nachname *" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className="flex gap-3">
          <div className="w-full">
            <FormField
              control={form.control}
              name={"phone" as Path<T>}
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormControl>
                    <Input {...field} placeholder="Telefonnummer *" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="flex flex-col gap-3">
        <div className="w-full">
          <FormField
            control={form.control}
            name={"name" as Path<T>}
            render={({ field }) => (
              <FormItem className="flex flex-col bg-white rounded-lg">
                <FormControl>
                  <Input {...field} placeholder="Unternehmensname *" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="w-full">
          <FormField
            control={form.control}
            name={"vatId" as Path<T>}
            render={({ field }) => (
              <FormItem className="flex flex-col bg-white rounded-lg">
                <FormControl>
                  <Input {...field} placeholder="Umsatzsteuer-ID *" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="w-full">
          <FormField
            control={form.control}
            name={"country" as Path<T>}
            render={({ field }) => (
              <FormItem className="flex flex-col bg-white rounded-lg">
                <FormControl>
                  <Select onValueChange={field.onChange} defaultValue={field.value} value={field.value}>
                    <SelectTrigger className={!field.value ? "text-muted-foreground" : ""}>
                      <SelectValue placeholder="Land auswählen *" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="DE">Deutschland</SelectItem>
                      <SelectItem value="AT">Österreich</SelectItem>
                      <SelectItem value="CH">Schweiz</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex gap-3">
          <div className="w-5/6">
            <FormField
              control={form.control}
              name={"street" as Path<T>}
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormControl>
                    <Input {...field} placeholder="Straße *" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="w-1/6">
            <FormField
              control={form.control}
              name={"houseNumber" as Path<T>}
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormControl>
                    <Input {...field} placeholder="Nr. *" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className="flex gap-3">
          <div className="w-3/5">
            <FormField
              control={form.control}
              name={"postCode" as Path<T>}
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormControl>
                    <Input {...field} placeholder="PLZ *" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="w-2/5">
            <FormField
              control={form.control}
              name={"city" as Path<T>}
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormControl>
                    <Input {...field} placeholder="Stadt *" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalFormItems;
