import { InternalUserListResponseDto } from "./internal/users";
import { UserSelectableEntityResponseDto } from "./user";
import { Paginated } from "./utils";

export enum OnboardingStage {
  AddUsers = "ADD_USERS",
  IntroductionVideo = "INTRODUCTION_VIDEO",
  Done = "DONE",
}

export enum BanReason {
  None = "NONE",
  PaymentOverdue = "PAYMENT_OVERDUE",
  DeactivatedByAdmin = "DEACTIVATED_BY_ADMIN",
}

export enum OrganizationStatusFilters {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  All = "ALL",
}

// TODO - mocked at the moment
export enum FlatRate {
  CLASSIC = "classic",
  PREMIUM = "premium",
}

export interface SelfCheckoutListResponseDto {
  _id: string;
  mail: string;
  name: string;
  allowedJobsCount: number;
  newCustomerDate: string;
  createdAt: string;
}

export interface CapacityCreationEntitiesResponseDto {
  capacityBySlotsNeeded: CapacityBySlotsNeededResponseDto[];
}

export interface CapacityBySlotsNeededResponseDto {
  slotsNeeded: number;
  capacity: FutureCapacityResponseDto[];
}

export interface FutureCapacityResponseDto {
  date: string;
  freeCapacitySlots: number;
}

export interface CreateSelfCheckoutRequestDto {
  firstName: string;
  lastName: string;
  mail: string;
  name: string;
  allowedJobsCount: number;
  newCustomerDate: string;
  vatId: string;
  street: string;
  houseNumber: string;
  postCode: string;
  city: string;
  country: string;
  invoiceMail: string;
  iban: string;
  bic: string;
}


export interface SelfCheckoutDetailsResponseDto {
  _id: string;
  firstName: string;
  lastName: string;
  mail: string;
  name: string;
  allowedJobsCount: number;
  newCustomerDate: string;
  vatId: string;
  street: string;
  houseNumber: string;
  postCode: string;
  city: string;
  country: string;
  invoiceMail: string;
  iban: string;
  bic: string;
  createdAt: string;
  updatedAt: string;
  freeCapacity: number;
  phone: string;
  selectableEntities: CapacityCreationEntitiesResponseDto;
}

export interface OrganizationInvitationListResponseDto {
  _id: string;
  firstName: string;
  lastName: string;
  mail: string;
  expiresAt: string;
  createdAt: string;
  name: string;
  userAlreadyCreated: boolean;
  isDeleteable: boolean;
}

export interface OrganizationListResponseDto {
  _id: string;
  name: string;
  customerNumber: string;
  belongsToStatus: string;
  statusDate: string;
  lastLogin: string;
  assignedInternalUserFullName: string;
  profilePictureUrl: string;
  isOpenable: boolean;
  rating: number;
}

export interface OrganizationListPaginatedResponseDtoWithInvitationCountAndEntities {
  invitationCount: number;
  selfCheckoutCount: number;
  organizations: Paginated<OrganizationListResponseDto>;
  filterSelectableEntities: OrganizationFilterSelectableEntities;
  listEntities: OrganizationListEntityResponseDto;
}

export interface OrganizationFilterSelectableEntities {
  internalUsers: UserSelectableEntityResponseDto[];
  userFilterUsed: string;
  status: OrganizationStatusFilters[];
  statusFilterUsed: string;
}

export interface OrganizationListEntityResponseDto {
  status: OrganizationStatusSelectableOptionResponseDto[];
}

export interface OrganizationStatusSelectableOptionResponseDto {
  id: string;
  value: string;
  needsStatusDate: string;
  banReason: string;
  nextStatus: string;
  color: string;
  icon: string;
}

export interface OrganizationDetailsResponseDto {
  name: string;
  customerNumber: string;
  debitReference: string;
  signatureDate: string;
  vatId: string;
  street: string;
  houseNumber: string;
  postCode: string;
  city: string;
  country: string;
  invoiceMail: string;
  allowedJobsCount: number;
  onboardingStage: OnboardingStage;
  banReason: BanReason;
  belongsToStatus: string;
  statusDate: string;
  lastLogin: string;
  assignedInternalUserFullName: string;
  selectableEntities: OrganizationDetailsSelectableEntities;
  iban: string;
  bic: string;
  phone: string;
  organizationAdmin: InternalUserListResponseDto & { mail: string };
  organizationUsers: (InternalUserListResponseDto & { mail: string })[];
}

export interface UpdateOrganizationRequestDto {
  name: string;
  debitReference: string;
  signatureDate: string;
  vatId: string;
  street: string;
  houseNumber: string;
  postCode: string;
  city: string;
  country: string;
  invoiceMail: string;
  allowedJobsCount: number;
  iban: string;
  bic: string;
  phone: string;
}

export interface OrganizationDetailsSelectableEntities {
  status: OrganizationStatusSelectableOptionResponseDto[];
  internalUsers: UserSelectableEntityResponseDto[];
}