import { useEffect } from "react";
import useVerified from "../../../../lib/hooks/useVerified";
import OnboardingPersonalForm from "./personal-form";

const OnboardingPersonalStep = () => {
  const { checkVerification } = useVerified();
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    checkVerification();
  }, [checkVerification]);

  return (
    <div className="min-h-screen bg-black relative w-screen">
      <div className="flex flex-col lg:flex-row w-full min-h-screen relative z-10">
        {/* Welcome Section */}
        <div className="w-full lg:w-2/3 flex flex-col justify-center items-center p-6 lg:p-0 md:text-left text-center">
          {!isMobile && (
            <div className="p-4 lg:p-20 flex flex-col gap-8 lg:gap-16">
              <div className="flex flex-col gap-4">
                <p className="text-white text-2xl lg:text-4xl font-bold">
                  Willkommen zur award-winning <br />{" "}
                  <span
                    style={{
                      background: "-webkit-linear-gradient(300deg, #5FD5DE 0%, #C0DE60 100%)",
                      WebkitTextFillColor: "transparent",
                      WebkitBackgroundClip: "text",
                    }}
                  >
                    #1 Design Flatrate
                  </span>{" "}
                  aus Deutschland!
                </p>
                <p className="text-white text-sm lg:text-base">
                  Grafikdesign | ADs | Präsentationen | Motion Design | & 100+ mehr
                </p>
              </div>
              <div>
                <img src="/awards.png" alt="awards" className="w-full h-full" />
              </div>
            </div>
          )}
        </div>

        {/* Form Section */}
        <div className="w-full lg:w-1/3 p-4 lg:pr-16 pr-4 flex items-center">
          <div className="bg-white rounded-xl p-6 lg:p-8 w-full">
            <OnboardingPersonalForm />
          </div>
        </div>
      </div>

      {/* Background Image */}
      <img
        src="/onboarding-bg.svg"
        alt="onboarding-bg"
        className="w-full h-full object-cover absolute top-0 left-0 z-0"
      />
    </div>
  );
};

export default OnboardingPersonalStep;
