import { useEffect, useState } from "react";
import useVerified from "../../../../lib/hooks/useVerified";
import TickIcon from "../../../../ui/components/icons/tick-icon";

const OnboardingWelcomeStep = () => {
  const { checkVerification } = useVerified();
  const [name, setName] = useState<string | undefined>(undefined);

  useEffect(() => {
    checkVerification().then((res) => {
      if (res && typeof res === "object" && "name" in res) {
        setName(res.name);
      }
    });
  }, [checkVerification]);

  return (
    <div className="min-h-screen bg-black relative w-screen">
      <div className="flex flex-col w-full min-h-screen relative z-10 justify-center items-center p-6">
        <div className="p-4 lg:p-20 flex flex-col gap-8 lg:gap-16">
          <div className="flex flex-col gap-4">
            <p
              className="text-white text-5xl md:text-7xl lg:text-9xl text-center font-bold"
              style={{
                background: "-webkit-linear-gradient(45deg, white 0%, black 150%)",
                WebkitTextFillColor: "transparent",
                WebkitBackgroundClip: "text",
              }}
            >
              Moin {name}
            </p>
            <p className="text-white text-2xl lg:text-4xl text-center">
              bei der{" "}
              <span
                style={{
                  background: "-webkit-linear-gradient(300deg, #5FD5DE 0%, #C0DE60 100%)",
                  WebkitTextFillColor: "transparent",
                  WebkitBackgroundClip: "text",
                }}
              >
                #1 Design Flatrate
              </span>
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row w-full lg:w-2/3 px-4 gap-8 md:gap-0">
          {/* First Card */}
          <div className="w-full md:w-[45%]">
            <div className="bg-white/10 rounded-2xl">
              <div className="flex flex-col gap-4 p-6 lg:p-8 text-center relative text-white flatrate-card rounded-xl">
                <h2 className="font-bold text-base lg:text-lg">Verbindliche Anfrage erhalten</h2>
                <p className="text-xs lg:text-sm">
                  Wir haben Deine Daten erhalten und <br />
                  prüfen diese auf ihre Vollständigkeit.
                </p>
                <div className="absolute -bottom-2 -right-2">
                  <TickIcon active={true} size={32} />
                </div>
              </div>
            </div>
          </div>

          {/* Connector */}
          <div className="hidden md:block h-0.5 w-[10%] bg-white/30 self-center"></div>

          {/* Second Card */}
          <div className="w-full md:w-[45%]">
            <div className="bg-white/10 rounded-2xl">
              <div className="flex flex-col gap-4 p-6 lg:p-8 text-center relative text-white/70 rounded-xl border border-white/60">
                <h2 className="font-bold text-base lg:text-lg">Bestätigung und Zugang</h2>
                <p className="text-xs lg:text-sm">
                  Du erhälst eine Auftragsbestätigung <br />
                  und einen Zugangslink zu 7i7® flow.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Background Image */}
      <img
        src="/onboarding-bg.svg"
        alt="onboarding-bg"
        className="w-full h-full object-cover absolute top-0 left-0 z-0"
      />
    </div>
  );
};

export default OnboardingWelcomeStep;
