import { CalendarIcon } from "lucide-react";
import { Link } from "react-router-dom";
import useApiRequest from "../../../lib/hooks/useRequest";
import { Button } from "../../../ui/components/ui/button";

const VideoScreen = () => {
  const { apiRequest } = useApiRequest();

  const finsihOnboardingStage = async () => {
    const res = await apiRequest("organizations/customer/finishOnboardingIntroductionVideoStage", "PATCH", {
      toast: {
        toastText: "Schritt erfolgreich abgeschlossen",
      },
    });
    if (res.status >= 200 && res.status < 300) {
      window.location.href = "/";
    }
  };

  return (
    <div className="flex flex-col h-full justify-around items-center px-4 sm:px-8 md:px-16 lg:px-32">
      <div className="bg-white p-4 sm:p-6 md:p-9 rounded-2xl flex flex-col items-center justify-between border border-[#D0D5DD] w-full max-w-[686px] gap-3">
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/AnTS3XdbgYE`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
          className="w-full h-[200px] sm:h-[300px] md:h-[400px] object-cover rounded-2xl"
        />
        <Link
          to="https://outlook.office365.com/owa/calendar/FabiOnboarding@7i7.de/bookings/"
          className="w-full"
          target="_blank"
        >
          <Button
            onClick={finsihOnboardingStage}
            type="button"
            variant="default"
            className="text-white font-bold flex items-center gap-2 hover:gap-3 w-full"
          >
            <CalendarIcon size="20" />
            <span>Onboarding Termin buchen</span>
          </Button>
        </Link>
      </div>
      <div className="flex flex-col gap-5 w-full mt-8">
        <div className="flex items-center text-xl font-bold">
          <div className="w-1/2 relative">
            <div className="absolute top-4 left-5 custom-gradient w-full h-2"></div>
            <div className="flex relative z-20 justify-center items-center text-white bg-[#12282A] w-10 h-10 rounded-full">
              1
            </div>
          </div>
          <div className="w-1/2 relative">
            <div className="absolute top-4 left-5 custom-gradient w-full h-2"></div>
            <div className="flex relative z-20 justify-center items-center text-white bg-[#12282A] w-10 h-10 rounded-full">
              2
            </div>
          </div>
          <div className="">
            <div className="flex relative z-20 justify-center items-center text-white bg-[#12282A] w-10 h-10 rounded-full">
              3
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between text-sm font-semibold">
          <div className="text-[#CCCCCC] w-24">Daten</div>
          <div className="text-[#CCCCCC] w-32 text-center">Benutzer</div>
          <div className="w-24 text-right">Onboarding</div>
        </div>
      </div>
    </div>
  );
};

export default VideoScreen;
