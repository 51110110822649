import { useCallback, useState } from "react";
import useApiRequest from "./useRequest";
import { Error as ErrorI } from "../../components/root/context";

/**
 * Hook to check if a user's email is verified
 * Redirects to verification page if not verified
 * @returns {Object} Object containing isVerified state and checkVerification function
 */
const useVerified = () => {
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const { apiRequest } = useApiRequest();

  const checkVerification = useCallback(async ({ redirect = true }: { redirect?: boolean } = {}) => {
    try {
      const res = await apiRequest("user-profile/userinfo", "GET");
      if (res.status === 200 && res.data) {
        setIsVerified(true);
        if (redirect) {
          window.location.href = "/";
        }
        return true;
      } else {
        setIsVerified(false);
        return {
          isVerified: false,
          error: res.error,
          name: (res.error as ErrorI).name,
        };
      }
    } catch (error) {
      console.error("Error checking verification status:", error);
      return false;
    }
  }, [apiRequest]);

  return { isVerified, checkVerification };
};

export default useVerified;
