import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Dispatch, SetStateAction, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { formatDateTime } from "../../../lib/hooks/utils";
import { SelfCheckoutListResponseDto } from "../../../lib/interfaces/organisation";
import { Pagination } from "../../../lib/interfaces/utils";
import { Button } from "../../../ui/components/ui/button";
import Preloader from "../../../ui/components/ui/preloader";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../ui/components/ui/table";
import RequestProcessor from "./requests-processor";
interface DataProps {
  data: SelfCheckoutListResponseDto[];
  setReload: Dispatch<SetStateAction<boolean>>;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  pageCount: number;
  pagination: Pagination;
  isLoading: boolean;
}

export const RequestsDataTable: React.FC<DataProps> = ({
  data,
  setReload,
  setPagination,
  pageCount,
  pagination,
  isLoading,
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRequestId, setSelectedRequestId] = useState<string | undefined>(
    searchParams.get("request-id") || undefined
  );

  const columns: ColumnDef<SelfCheckoutListResponseDto>[] = [
    {
      id: "picture",
      header: "",
      cell: ({ row }) => (
        <div className="max-w-[54px] relative min-w-12">
          <img
            src="/user-default.svg"
            alt={row.original.name}
            width={54}
            height={54}
            className="rounded-full h-14 w-14 object-cover"
          />
        </div>
      ),
    },
    {
      accessorKey: "name",
      header: "Name",
      cell: ({ row }) => {
        const user = row.original;
        return (
          <div className="flex flex-col text-sm">
            <p className="font-bold">{user.name}</p>
            <p className="text-[#888] font-medium">{user.mail}</p>
          </div>
        );
      },
    },
    {
      accessorKey: "allowedJobsCount",
      header: "Jobs",
      cell: ({ row }) => (
        <div className="flex items-center gap-2 text-sm text-[#D0D5DD]">{row.original.allowedJobsCount}</div>
      ),
    },
    {
      accessorKey: "newCustomerDate",
      header: "Datum",
      cell: ({ row }) => (
        <div className="flex items-center gap-2 text-sm text-[#D0D5DD]">
          {formatDateTime(row.original.newCustomerDate)}
        </div>
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    pageCount,
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    initialState: { pagination: { pageSize: 5 } },
    state: {
      pagination,
      sorting,
      rowSelection,
    },
  });

  return (
    <div className="w-full">
      <div className="rounded-md overflow-hidden">
        {isLoading ? (
          <Preloader />
        ) : (
          <Table className="border-separate border-spacing-y-4">
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHead key={header.id} className="text-[#D0D5DD] h-6">
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    className="bg-white hover:bg-white cursor-pointer"
                    onClick={() => {
                      setSelectedRequestId(row.original._id);
                      setSearchParams({ "request-id": row.original._id });
                    }}
                  >
                    {row.getVisibleCells().map((cell, j) => (
                      <TableCell
                        key={cell.id}
                        className={`border-none p-2 ${j === 0 ? "w-24" : ""} ${j === 0 ? "rounded-l-[61px]" : ""} ${
                          j === row.getVisibleCells().length - 1 ? "rounded-r-[61px]" : ""
                        }`}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    Keine ausstehenden Anfragen
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </div>

      <div className="flex items-center justify-between py-4 my-2">
        <Button
          className="text-[17px] text-[#888] hover:text-[#12282A]"
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          zurück
        </Button>
        <span className="text-sm text-[#D0D5DD]">
          Seite {table.getState().pagination.pageIndex + 1} von {table.getPageCount()}
        </span>
        <Button
          className="text-[17px] text-[#888] hover:text-[#12282A]"
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          nächste
        </Button>
      </div>
      {selectedRequestId && (
        <RequestProcessor id={selectedRequestId} setSelectedRequestId={setSelectedRequestId} setReload={setReload} />
      )}
    </div>
  );
};

export default RequestsDataTable;
