import { zodResolver } from "@hookform/resolvers/zod";
import { UpdateIcon } from "@radix-ui/react-icons";
import { LoaderCircle, UserIcon } from "lucide-react";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import { ErrorEntity, Error as ErrorI } from "../../../components/root/context";
import useApiRequest from "../../../lib/hooks/useRequest";
import { SelfCheckoutDetailsResponseDto } from "../../../lib/interfaces/organisation";
import { Button } from "../../../ui/components/ui/button";
import DataFetchParent from "../../../ui/components/ui/data-fetch";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../../ui/components/ui/dialog";
import { Form } from "../../../ui/components/ui/form";
import { createPaymentDataSchema, personalDataSchema } from "./form-types";
import PaymentFormItems from "./payment-form-items";
import PersonalFormItems from "./personal-form-items";
const RequestProcessor = ({
  id,
  setSelectedRequestId,
  setReload,
}: {
  id: string;
  setSelectedRequestId: Dispatch<SetStateAction<string | undefined>>;
  setReload: Dispatch<SetStateAction<boolean>>;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [item, setItem] = useState<SelfCheckoutDetailsResponseDto>();
  const [open, setOpen] = useState(id !== undefined);
  const [isAcceptedLoading, setIsAcceptedLoading] = useState(false);
  const [isRejectedLoading, setIsRejectedLoading] = useState(false);
  const [, setSearchParams] = useSearchParams();

  const { apiRequest } = useApiRequest();

  const getItem = useCallback(async () => {
    setIsLoading(true);
    const res = await apiRequest<SelfCheckoutDetailsResponseDto>(`self-checkout/${id}`, "GET");
    setItem(res.data);
    setIsLoading(false);
  }, [apiRequest, id]);

  useEffect(() => {
    getItem();
  }, [id, getItem]);

  useEffect(() => {
    setOpen(id !== undefined);
  }, [id]);

  const toggleAccept = async (type: "accept" | "decline") => {
    if (type === "accept") {
      setIsAcceptedLoading(true);
    } else {
      setIsRejectedLoading(true);
    }
    await apiRequest(`self-checkout/${id}/${type}`, "POST", {
      toast: {
        toastText: "Anfrage erfolgreich bearbeitet",
      },
    });
    setSelectedRequestId(undefined);
    setSearchParams({});
    setOpen(false);
    setReload((prev) => !prev);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          setSelectedRequestId(undefined);
          setSearchParams({});
        }
        setOpen(open);
      }}
    >
      <DialogContent className="sm:max-w-md min-w-[560px] py-4 px-6 gap-5">
        <DialogHeader className="mb-7">
          <DialogTitle className="flex items-center gap-3">
            <UserIcon />
            <p className="text-[25px] font-bold">Kundenanfrage</p>
          </DialogTitle>
        </DialogHeader>

        <DataFetchParent
          isLoading={isLoading}
          data={item}
          renderElement={(data: SelfCheckoutDetailsResponseDto) => (
            <div className="flex flex-col gap-4">
              <DialogFooter className="sm:justify-start flex gap-2">
                <Button
                  variant="destructive"
                  onClick={() => toggleAccept("decline")}
                  className="w-1/2 gap-2"
                  disabled={isRejectedLoading || isAcceptedLoading}
                >
                  {isRejectedLoading && <LoaderCircle className="animate-spin" />}
                  Ablehnen
                </Button>
                <Button
                  variant="brand"
                  onClick={() => toggleAccept("accept")}
                  className="w-1/2 gap-2"
                  disabled={isAcceptedLoading || isRejectedLoading}
                >
                  {isAcceptedLoading && <LoaderCircle className="animate-spin" />}
                  Akzeptieren
                </Button>
              </DialogFooter>
              <hr />
              <Forms data={data} id={id} />
            </div>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

const Forms = ({ data, id }: { data: SelfCheckoutDetailsResponseDto; id: string }) => {
  const { apiRequest } = useApiRequest();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [ibanValid, setIbanValid] = useState<boolean | undefined>(undefined);
  const [bicValid, setBicValid] = useState<boolean | undefined>(undefined);
  const [lastValidatedIban, setLastValidatedIban] = useState<string>("");
  const [lastValidatedBic, setLastValidatedBic] = useState<string>("");
  const isGermany = data.country === "DE";

  const validateIban = async (iban: string, bic: string): Promise<{ iban: boolean; bic: boolean }> => {
    // Skip validation if values haven't changed
    if (iban === lastValidatedIban && bic === lastValidatedBic) {
      return { iban: ibanValid ?? false, bic: bicValid ?? false };
    }

    // Skip validation if already validating
    if (isValidating) {
      return { iban: false, bic: false };
    }

    setIsValidating(true);
    try {
      const response = await apiRequest(`self-checkout/admin/iban-validation?iban=${iban}&bic=${bic}`, "GET");

      // Update last validated values
      setLastValidatedIban(iban);
      setLastValidatedBic(bic);

      if (response.status === 200) {
        setIbanValid(true);
        setBicValid(true);
        return { iban: true, bic: true };
      } else if (response.status === 400 && response.error) {
        const errorEntity = (response.error as ErrorI).entity;
        if (errorEntity === ErrorEntity.InvalidBicForbidden) {
          setBicValid(false);
          setIbanValid(true);
          return { iban: true, bic: false };
        } else if (errorEntity === ErrorEntity.InvalidIbanForbidden) {
          setIbanValid(false);
          setBicValid(true);
          return { iban: false, bic: true };
        } else if (errorEntity === ErrorEntity.InvalidIbanAndBicForbidden) {
          setIbanValid(false);
          setBicValid(false);
          return { iban: false, bic: false };
        }
        return { iban: false, bic: false };
      }
      // Handle any other response status
      setIbanValid(false);
      setBicValid(false);
      return { iban: false, bic: false };
    } catch (error) {
      // Handle any unexpected errors
      setIbanValid(false);
      setBicValid(false);
      return { iban: false, bic: false };
    } finally {
      setIsValidating(false);
    }
  };

  const paymentDataSchema = createPaymentDataSchema(validateIban, isGermany, true);
  const combinedDataSchema = z.intersection(personalDataSchema, paymentDataSchema);
  type CombinedDataSchemaType = z.infer<typeof combinedDataSchema>;

  const getSelectedDate = () => {
    // get the nearest date to the newCustomerdate from the data.selectableEntities
    const newCustomerDate = new Date(data.newCustomerDate);
    const selectableEntities = data.selectableEntities;
    const nearestDate = selectableEntities.capacityBySlotsNeeded[data.allowedJobsCount - 1].capacity.find(
      (capacity) => {
        const date = new Date(capacity.date);
        return date >= newCustomerDate;
      }
    );
    return nearestDate?.date;
  };

  const form = useForm<CombinedDataSchemaType>({
    resolver: zodResolver(combinedDataSchema),
    mode: "onBlur",
    defaultValues: {
      firstName: data.firstName || "",
      lastName: data.lastName || "",
      phone: data.phone || "",
      name: data.name || "",
      country: data.country || "",
      vatId: data.vatId || "",
      street: data.street || "",
      houseNumber: data.houseNumber || "",
      postCode: data.postCode || "",
      city: data.city || "",
      invoiceMail: data.invoiceMail || "",
      bic: data.bic || "",
      iban: data.iban || "",
      allowedJobsCount: data.allowedJobsCount || 0,
      newCustomerDate: getSelectedDate(),
    },
  });

  // Trigger validation when form values change, except for IBAN and BIC which are validated on blur
  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      // Skip IBAN and BIC validation on change, they will be validated on blur
      if (name !== "iban" && name !== "bic") {
        form.trigger(name as keyof CombinedDataSchemaType);
      }
    });
    return () => subscription.unsubscribe();
  }, [form]);

  const onSubmit = async (data: CombinedDataSchemaType) => {
    setIsSubmitting(true);

    // Combine data from both steps
    const requestData = {
      ...data,
      invoiceMail: data.invoiceMail !== "" ? data.invoiceMail : undefined,
      bic: data.bic !== "" ? data.bic : undefined,
      iban: data.iban !== "" ? data.iban : undefined,
    };

    // Make API request
    await apiRequest(`self-checkout/${id}`, "PATCH", {
      body: requestData,
      toast: {
        toastText: "Kundenanfrage erfolgreich bearbeitet",
      },
    });
    setIsSubmitting(false);
  };

  return (
    <div className="flex flex-col gap-4">
      <p className="text-lg font-bold">Kundenanfrage bearbeiten</p>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit, (errors) => console.log(errors))} className="flex flex-col gap-4">
          <PersonalFormItems form={form} />
          <hr />
          <PaymentFormItems
            form={form}
            startDateValues={data.selectableEntities}
            isValidating={isValidating}
            ibanValid={ibanValid}
            bicValid={bicValid}
            setSelectedPlan={() => {}}
            isGermany={isGermany}
            isEditing={true}
          />
          <hr />
          <div className="flex gap-2 flex-col">
            <Button type="submit" disabled={isSubmitting || !form.formState.isValid} className="self-end gap-2">
              <UpdateIcon className="w-4 h-4" />
              Aktualisieren
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default RequestProcessor;
