export enum NotificationKind {
  JOB = "job-notification",
  SELF_CHECKOUT = "self-checkout-notification",
}

export interface NotificationListResponseDto {
  _id: string;
  entityId: string;
  entityName: string;
  entityIncrementId?: string;
  message: string;
  read: string;
  kind: NotificationKind;
  triggerUserProfilePictureUrl: string;
  createdAt: string;
}
