import { z } from "zod";

const telRegex = /^\+?(\d+|\([0-9]+\))([0-9]*[-()]?){5,13}\d$/;

export const personalDataSchema = z.object({
  firstName: z
    .string()
    .max(45, {
      message: "Der Name darf höchstens 45 Zeichen lang sein",
    })
    .min(1, {
      message: "Vorname ist erforderlich",
    }),
  lastName: z.string().min(1, {
    message: "Nachname ist erforderlich",
  }),
  phone: z
    .string()
    .trim()
    .transform((val) => val.replace(/\s/g, ""))
    .refine((val) => telRegex.test(val), {
      message:
        "Ungültige Telefonnummer. Geben Sie es in folgendem Format ein: +49123123123",
    }),
  name: z.string().min(1, {
    message: "Unternehmensname ist erforderlich",
  }),
  country: z.string().min(1, {
    message: "Land ist erforderlich",
  }),
  vatId: z.string().min(1, {
    message: "Umsatzsteuer-ID ist erforderlich",
  }),
  street: z.string().min(1, {
    message: "Straße ist erforderlich",
  }),
  houseNumber: z.string().min(1, {
    message: "Hausnummer ist erforderlich",
  }),
  postCode: z.string().min(1, {
    message: "PLZ ist erforderlich",
  }),
  city: z.string().min(1, {
    message: "Stadt ist erforderlich",
  }),
});

export type PersonalDataSchemaType = z.infer<typeof personalDataSchema>;

// Base payment schema without IBAN validation
export const createBasePaymentDataSchema = (
  isGermany: boolean = true,
  isUpdating: boolean = false
) => {
  // Create schema with conditional fields
  return z.object({
    invoiceMail: z.union([
      z.literal(""),
      z.string().email("Bitte geben Sie eine E-Mail Adresse ein"),
    ]),
    bic: isGermany
      ? z.string().min(1, { message: "BIC ist erforderlich" })
      : z.string().optional(),
    iban: isGermany
      ? z.string().min(1, { message: "IBAN ist erforderlich" })
      : z.string().optional(),
    allowedJobsCount: z.number().min(1, {
      message: "Anzahl der erlaubten Jobs ist erforderlich",
    }),
    newCustomerDate: z.string().min(1, {
      message: "Startdatum ist erforderlich",
    }),
    termsOfService: isUpdating
      ? z.boolean().optional()
      : z.boolean().refine((data) => data, {
        message: "Sie müssen die Nutzungsbedingungen akzeptieren",
      }),
    paymentConfirmation:
      isUpdating || !isGermany
        ? z.boolean().optional()
        : z.boolean().refine((data) => data, {
          message: "Sie müssen die Zahlungsbestätigung bestätigen",
        }),
    minimumDuration: isUpdating
      ? z.boolean().optional()
      : z.boolean().refine((data) => data, {
        message: "Sie müssen die Mindestlaufzeit akzeptieren",
      }),
  });
};

// Function to create payment schema with IBAN validation
export const createPaymentDataSchema = (
  validateIban?: (
    iban: string,
    bic: string
  ) => Promise<{ iban: boolean; bic: boolean } | undefined>,
  isGermany: boolean = true,
  isUpdating: boolean = false
) => {
  const schema = createBasePaymentDataSchema(isGermany, isUpdating);

  // Add IBAN validation if provided
  if (validateIban && isGermany) {
    // Use a single refine call with a shared validation result
    const refinedSchema = schema.superRefine(async (data, ctx) => {
      if (!isGermany || !data.iban || !data.bic) return;

      // Call validateIban only once
      const result = await validateIban(data.iban, data.bic);

      // Add validation errors if needed
      if (!result?.iban) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Ungültige IBAN",
          path: ["iban"],
        });
      }

      if (!result?.bic) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Ungültige BIC",
          path: ["bic"],
        });
      }
    });

    return refinedSchema;
  }

  return schema;
};

export const paymentDataSchema = createBasePaymentDataSchema();
export type PaymentDataSchemaType = z.infer<typeof paymentDataSchema>;

// Combined schema
export const createCombinedDataSchema = (
  validateIban?: (
    iban: string,
    bic: string
  ) => Promise<{ iban: boolean; bic: boolean }>,
  isGermany: boolean = true,
  isUpdating: boolean = false
) => {
  const paymentSchema = createPaymentDataSchema(
    validateIban,
    isGermany,
    isUpdating
  );
  return z.intersection(personalDataSchema, paymentSchema);
};
