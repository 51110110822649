import { useEffect, useState } from "react";
import PaymentFormFetcher from "./payment-form";
import TickIcon from "../../../../ui/components/icons/tick-icon";
import { FlatRate } from "../../../../lib/interfaces/organisation";
import useVerified from "../../../../lib/hooks/useVerified";
import { useNavigate } from "react-router-dom";

const servicesList = [
  {
    name: "Standard Geschwindigkeit",
    premiumText: "Doppelte Geschwindigkeit",
    tier: FlatRate.CLASSIC,
  },
  {
    name: "100+ Design Leistungen",
    tier: FlatRate.CLASSIC,
  },
  {
    name: "Fester Art Director",
    tier: FlatRate.CLASSIC,
  },
  {
    name: "Motion Design",
    tier: FlatRate.PREMIUM,
  },
  {
    name: "Videobearbeitung",
    tier: FlatRate.PREMIUM,
  },
  {
    name: "Schulungen",
    tier: FlatRate.PREMIUM,
  },
];

const highlightList = [
  {
    name: "Persönlicher Support",
    premiumText: "Premium Support",
    tier: FlatRate.CLASSIC,
  },
  {
    name: "Deutsches Designteam",
    tier: FlatRate.CLASSIC,
  },
  {
    name: "Renommierte Designer",
    tier: FlatRate.CLASSIC,
  },
  {
    name: "Unbegrenzte Revisionen",
    tier: FlatRate.CLASSIC,
  },
  {
    name: "Zugriff zur 7i7® Plattform",
    tier: FlatRate.CLASSIC,
  },
  {
    name: "Strategische Beratung",
    tier: FlatRate.PREMIUM,
  },
  {
    name: "Unbegrenzte Benutzeranzahl",
    tier: FlatRate.PREMIUM,
  },
  {
    name: "Wöchentliche Meetings",
    tier: FlatRate.PREMIUM,
  },
];

const OnboardingPaymentStep = () => {
  const [selectedPlan, setSelectedPlan] = useState<FlatRate>(FlatRate.CLASSIC);
  const { checkVerification } = useVerified();
  const navigate = useNavigate();

  // Check if personal data exists
  useEffect(() => {
    const personalData = localStorage.getItem("selfCheckoutPersonalData");
    if (!personalData) {
      checkVerification();
    }
  }, [navigate, checkVerification]);

  return (
    <div className="min-h-screen bg-black relative w-screen">
      <div className="flex flex-col lg:flex-row w-full min-h-screen relative z-10">
        {/* Plans Section */}
        <div className="w-full lg:w-2/3 flex flex-col justify-center items-center p-6 lg:p-0">
          <div className="p-4 lg:p-20 flex flex-col lg:flex-row gap-8 lg:gap-16 w-full max-w-5xl">
            <TierCard
              tier={FlatRate.CLASSIC}
              isSelected={selectedPlan === FlatRate.CLASSIC}
              setTier={setSelectedPlan}
            />
            <TierCard
              tier={FlatRate.PREMIUM}
              isSelected={selectedPlan === FlatRate.PREMIUM}
              setTier={setSelectedPlan}
            />
          </div>
        </div>

        {/* Form Section */}
        <div className="w-full lg:w-1/3 p-4 lg:pr-16 pr-4 flex items-center">
          <div className="bg-white rounded-xl p-6 lg:p-8 w-full">
            <PaymentFormFetcher selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} />
          </div>
        </div>
      </div>

      {/* Background Image */}
      <img
        src="/onboarding-bg.svg"
        alt="onboarding-bg"
        className="w-full h-full object-cover absolute top-0 left-0 z-0"
      />
    </div>
  );
};

const TierCard = ({
  tier,
  isSelected,
  setTier,
}: {
  tier: FlatRate;
  isSelected: boolean;
  setTier: (tier: FlatRate) => void;
}) => {
  const handleClick = () => {
    if (!isSelected) {
      setTier(tier);
    }
  };

  return (
    <div onClick={handleClick} className="cursor-pointer relative w-full lg:w-1/2">
      <div className="bg-white/10 rounded-2xl">
        <div
          className={`flex flex-col gap-6 p-6 lg:p-14 pb-20 rounded-xl relative ${isSelected ? "flatrate-card" : ""}`}
        >
          <div
            style={{
              background: "-webkit-linear-gradient(45deg, white 0%, black 150%)",
              WebkitTextFillColor: "transparent",
              WebkitBackgroundClip: "text",
            }}
            className="flex flex-col gap-4"
          >
            <h2 className="text-3xl lg:text-4xl font-bold">{tier === FlatRate.CLASSIC ? "Classic" : "Premium"}</h2>
            <p className="text-3xl lg:text-4xl font-bold">
              {tier === FlatRate.CLASSIC ? "1.850€" : "3.700€"} <span className="text-base lg:text-lg">/Monat</span>
            </p>
          </div>
          <p className="text-sm text-white">
            {tier === FlatRate.CLASSIC
              ? "Einfache Power, 100+ Design Leistungen & top Designer."
              : "Doppelte Power, intensiver Support & maximaler Output."}
          </p>
          <hr />
          {/* Services Section */}
          <div className="flex flex-col gap-2 text-white">
            <p className="text-base lg:text-lg font-bold pl-7">Leistungen:</p>
            <div className="flex flex-col gap-2 pl-2">
              {servicesList.map((service) => (
                <div className="flex gap-2 items-center text-sm lg:text-base" key={service.name}>
                  <TickIcon active={tier === FlatRate.CLASSIC ? service.tier === tier : true} />
                  <p
                    className={`${(tier === FlatRate.CLASSIC ? service.tier === tier : true) ? "" : "text-[#5D5D5D]"}`}
                  >
                    {service.premiumText && tier === FlatRate.PREMIUM ? service.premiumText : service.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
          {/* Highlights Section */}
          <div className="flex flex-col gap-2 text-white">
            <p className="text-base lg:text-lg font-bold pl-7">Highlights:</p>
            <div className="flex flex-col gap-2 pl-2">
              {highlightList.map((highlight) => (
                <div className="flex gap-2 items-center text-sm lg:text-base" key={highlight.name}>
                  <TickIcon active={tier === FlatRate.CLASSIC ? highlight.tier === tier : true} />
                  <p
                    className={`${
                      (tier === FlatRate.CLASSIC ? highlight.tier === tier : true) ? "" : "text-[#5D5D5D]"
                    }`}
                  >
                    {highlight.premiumText && tier === FlatRate.PREMIUM ? highlight.premiumText : highlight.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`text-center w-fit text-white px-8 py-1 border m-auto rounded-b-2xl ${
          isSelected ? "" : "bg-white/10 border-white/0"
        }`}
        style={{
          boxShadow: isSelected ? "0px 0px 18.6px 0px rgba(95, 213, 222, 0.50)" : "none",
          background: isSelected ? "linear-gradient(300deg, #5FD5DE 0%, #C0DE60 100%)" : "",
        }}
      >
        <p className="font-bold text-sm lg:text-base">{isSelected ? "ausgewählt" : "auswählen"}</p>
      </div>
    </div>
  );
};

export default OnboardingPaymentStep;
