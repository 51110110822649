import { zodResolver } from "@hookform/resolvers/zod";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import UserEditorDialog from "../../../components/order/customer/user-editor";
import useApiRequest from "../../../lib/hooks/useRequest";
import { formatDateDetail } from "../../../lib/hooks/utils";
import { InternalUserListResponseDto, InternalUserResponseDto } from "../../../lib/interfaces/internal/users";
import {
  OnboardingStage,
  OrganizationDetailsResponseDto,
  UpdateOrganizationRequestDto,
} from "../../../lib/interfaces/organisation";
import { Button } from "../../../ui/components/ui/button";
import DataFetchParent from "../../../ui/components/ui/data-fetch";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../../ui/components/ui/form";
import { H4 } from "../../../ui/components/ui/headings";
import { Input } from "../../../ui/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../ui/components/ui/select";

const OrganisationDetailParent = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [organisation, setOrganisation] = useState<OrganizationDetailsResponseDto>();
  const [reload, setReload] = useState(false);
  const { apiRequest } = useApiRequest();

  const fetchOrganisation = useCallback(async () => {
    setIsLoading(true);
    const res = await apiRequest<OrganizationDetailsResponseDto>(`organizations/internal/${id}`, "GET");
    setIsLoading(false);
    if (res.data) {
      setOrganisation(res.data);
    }
  }, [apiRequest, id]);

  useEffect(() => {
    fetchOrganisation();
  }, [fetchOrganisation, reload]);

  if (!id) {
    return <div>Organisation not found</div>;
  }

  return (
    <DataFetchParent
      data={organisation}
      renderElement={(data) => <OrganisationDetails organisationId={id} setReload={setReload} organisation={data} />}
      isLoading={isLoading}
    />
  );
};

const OrganisationDetails = ({
  organisation,
  organisationId,
  setReload,
}: {
  organisation: OrganizationDetailsResponseDto;
  organisationId: string;
  setReload: Dispatch<SetStateAction<boolean>>;
}) => {
  const { apiRequest } = useApiRequest();
  const [mode, setMode] = useState<"view" | "edit">("view");
  const statusSchema = z
    .object({
      status: z.string().min(1, {
        message: "Status ist erforderlich",
      }),
      statusDate: z.string().optional(),
    })
    .refine(
      (data) => {
        if (selectedUsesDate(data.status)) {
          return data.statusDate !== "" && data.statusDate !== undefined && data.statusDate !== null;
        }
        return true;
      },
      {
        message: "Statusdatum ist erforderlich",
        path: ["statusDate"],
      }
    );

  type StatusSchema = z.infer<typeof statusSchema>;

  const getDateString = () => {
    const date = new Date(organisation.statusDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month.toString().length === 1 ? "0" + month : month}-${
      day.toString().length === 1 ? "0" + day : day
    }`;
  };

  console.log(organisation.statusDate);

  const form = useForm<StatusSchema>({
    resolver: zodResolver(statusSchema),
    defaultValues: {
      status: organisation.belongsToStatus,
      statusDate: organisation.statusDate !== null ? getDateString() : "",
    },
  });

  const updateStatus = async (data: StatusSchema) => {
    const req = {
      belongsToStatus: data.status,
      statusDate: data.statusDate ? new Date(data.statusDate).toISOString() : null,
    };

    await apiRequest(`organizations/internal/${organisationId}/status`, "PATCH", {
      body: req,
      toast: {
        toastText: "Status erfolgreich aktualisiert",
      },
    });
    setReload((prev) => !prev);
  };

  const resetAdminPassword = async () => {
    await apiRequest(`organizations/internal/${organisationId}/resetAdminPassword`, "POST", {
      toast: {
        toastText: "Passwort erfolgreich zurückgesetzt",
      },
    });
  };

  const selectedUsesDate = (safeStatus: string) => {
    return organisation.selectableEntities?.status.find((status) => status.id === safeStatus)?.needsStatusDate;
  };

  const changeAssignedInternalUser = async (data: z.infer<typeof assignedFormSchema>) => {
    await apiRequest(`organizations/internal/${organisationId}/assignedInternalUser`, "PATCH", {
      body: {
        assignedInternalUserId: data.assignedInternalUserId,
      },
      toast: {
        toastText: "Benutzer geändert",
      },
    });
    setReload((prev) => !prev);
  };

  const assignedFormSchema = z.object({
    assignedInternalUserId: z.string().min(1, {
      message: "Benutzer ist erforderlich",
    }),
  });

  const assignedForm = useForm({
    resolver: zodResolver(assignedFormSchema),
    defaultValues: {
      assignedInternalUserId:
        organisation.selectableEntities.internalUsers.find(
          (user) => user.value === organisation.assignedInternalUserFullName
        )?._id ?? "",
    },
  });

  const convertOnboardingStage = (stage: OnboardingStage) => {
    switch (stage) {
      case OnboardingStage.AddUsers:
        return "Benutzer hinzufügen";
      case OnboardingStage.Done:
        return "Abgeschlossen";
      case OnboardingStage.IntroductionVideo:
        return "Einführungsvideo";
      default:
        return "Nicht gestartet";
    }
  };

  return (
    <div className="flex flex-col gap-5 h-full">
      {/* <div className="flex flex-col gap-5 h-full"> */}
      <div className="flex justify-between">
        <div>
          <h1 className="text-[32px] leading-[38px] font-bold text-[#12282A]">{organisation.name}</h1>
          <p className="text-[16px] text-[#888]">
            Ansprechpartner: {organisation.assignedInternalUserFullName ?? "Nicht zugewiesen"}
          </p>
        </div>
        <Form {...form}>
          <form onSubmit={assignedForm.handleSubmit(changeAssignedInternalUser)} className="flex flex-col gap-7 h-full">
            <FormField
              control={assignedForm.control}
              name="assignedInternalUserId"
              render={({ field }) => (
                <FormItem className="flex flex-col h-full rounded-lg">
                  <FormControl>
                    <Select
                      onValueChange={(value) => {
                        field.onChange(value);
                        assignedForm.handleSubmit(changeAssignedInternalUser)();
                      }}
                      value={field.value}
                    >
                      <FormControl>
                        <SelectTrigger
                          className="gap-3 h-10 items-center rounded-2xl px-3 py-2 border text-sm inline-flex justify-center whitespace-nowrap font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border-input bg-background hover:bg-accent hover:text-accent-foreground"
                          selectIconVariant="styled"
                        >
                          <SelectValue placeholder={organisation.assignedInternalUserFullName ?? "Benutzer zuweisen"} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="NONE">Keinen Benutzer zuweisen</SelectItem>
                        {organisation.selectableEntities.internalUsers.map((user) => (
                          <SelectItem key={user._id} value={user._id}>
                            {user.value}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
      </div>
      <div className="flex flex-col gap-5 h-full">
        <div className="flex flex-col py-7 px-9 bg-white rounded-lg gap-[14px]">
          <H4>Daten zum Unternehmen</H4>
          {mode === "view" && (
            <div className="grid grid-cols-3">
              <p className="text-[16px] text-[#888]">Kundennummer: {organisation.customerNumber}</p>
              <p className="text-[16px] text-[#888]">Debitreferenz: {organisation.debitReference}</p>
              <p className="text-[16px] text-[#888]">Signaturdatum: {formatDateDetail(organisation.signatureDate)}</p>
              <p className="text-[16px] text-[#888]">USt-IdNr.: {organisation.vatId}</p>
              <p className="text-[16px] text-[#888]">Straße: {organisation.street}</p>
              <p className="text-[16px] text-[#888]">Hausnummer: {organisation.houseNumber}</p>
              <p className="text-[16px] text-[#888]">PLZ: {organisation.postCode}</p>
              <p className="text-[16px] text-[#888]">Ort: {organisation.city}</p>
              <p className="text-[16px] text-[#888]">Land: {organisation.country}</p>
              <p className="text-[16px] text-[#888]">Rechnungsmail: {organisation.invoiceMail}</p>
              <p className="text-[16px] text-[#888]">Erlaubte Jobsanzahl: {organisation.allowedJobsCount}</p>
              <p className="text-[16px] text-[#888]">
                Onboarding Stage: {convertOnboardingStage(organisation.onboardingStage)}
              </p>
              <p className="text-[16px] text-[#888]">IBAN: {organisation.iban}</p>
              <p className="text-[16px] text-[#888]">BIC: {organisation.bic}</p>
              <p className="text-[16px] text-[#888]">Telefon: {organisation.phone}</p>
            </div>
          )}
          {mode === "edit" && (
            <OrganisationEditor organisation={organisation} setReload={setReload} organisationId={organisationId} />
          )}
          <div className="flex gap-4">
            <Button
              className="w-fit"
              onClick={() => setMode((prev) => (prev === "view" ? "edit" : "view"))}
              variant={mode === "view" ? "default" : "outline"}
            >
              {mode === "view" ? "Bearbeiten" : "Abbrechen"}
            </Button>
            <Button
              type="submit"
              variant="destructive"
              className="text-white flex items-center gap-4 w-fit"
              onClick={resetAdminPassword}
            >
              <p>Admin Passwort zurücksetzen</p>
            </Button>
          </div>
        </div>
        <div className="flex flex-col py-7 px-9 bg-white rounded-lg gap-[14px]">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(updateStatus)} className="flex flex-col gap-3 h-full">
              <FormLabel className="text-[16px] font-bold text-[#12282A]">Status</FormLabel>
              <div className="w-1/2 flex flex-col gap-2">
                <div className="flex gap-2">
                  <div className="grow w-1/2">
                    <FormField
                      control={form.control}
                      name="status"
                      render={({ field }) => (
                        <FormItem className="flex flex-col bg-white rounded-lg">
                          <FormControl>
                            <Select
                              onValueChange={(value) => {
                                field.onChange(value);
                              }}
                              defaultValue={field.value}
                            >
                              <FormControl>
                                <SelectTrigger className="gap-3 h-10 rounded-2xl px-3 py-2 border text-sm inline-flex whitespace-nowrap font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border-input bg-background hover:bg-accent hover:text-accent-foreground">
                                  <SelectValue placeholder="Status wählen" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {organisation.selectableEntities?.status.map((status) => (
                                  <SelectItem key={status.id} value={status.id}>
                                    {status.value}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="grow w-1/2">
                    {selectedUsesDate(form.watch("status")) ? (
                      <>
                        <FormField
                          control={form.control}
                          name="statusDate"
                          render={({ field }) => (
                            <FormItem className="flex flex-col bg-white rounded-lg">
                              <FormControl>
                                <Input
                                  type="date"
                                  {...field}
                                  className="w-full h-10 px-3 text-[#12282A] border border-[#d1d5db] rounded-lg"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <Button type="submit" variant="default" className="text-white font-bold flex items-center gap-4 w-fit">
                  <p>Speichern</p>
                </Button>
              </div>
            </form>
          </Form>
        </div>
        <div className="flex flex-col py-7 px-9 bg-white rounded-lg gap-[14px]">
          <p className="text-[16px] font-bold text-[#12282A]">Benutzer</p>
          <OrganisationUsers
            organizationAdmin={organisation.organizationAdmin}
            organizationUsers={organisation.organizationUsers}
            setReload={setReload}
            organisationId={organisationId}
          />
        </div>
      </div>
    </div>
  );
};

const OrganisationEditor = ({
  organisation,
  organisationId,
  setReload,
}: {
  organisation: OrganizationDetailsResponseDto;
  setReload: Dispatch<SetStateAction<boolean>>;
  organisationId: string;
}) => {
  const { apiRequest } = useApiRequest();

  const formSchema = z.object({
    name: z.string().min(1, {
      message: "Name ist erforderlich",
    }),
    debitReference: z.string().min(1, {
      message: "Debitreferenz ist erforderlich",
    }),
    signatureDate: z.string().min(1, {
      message: "Signaturdatum ist erforderlich",
    }),
    vatId: z.string().min(1, {
      message: "USt-IdNr. ist erforderlich",
    }),
    street: z.string().min(1, {
      message: "Straße ist erforderlich",
    }),
    houseNumber: z.string().min(1, {
      message: "Hausnummer ist erforderlich",
    }),
    postCode: z.string().min(1, {
      message: "Postleitzahl ist erforderlich",
    }),
    city: z.string().min(1, {
      message: "Ort ist erforderlich",
    }),
    country: z.string().min(1, {
      message: "Land ist erforderlich",
    }),
    invoiceMail: z.string().min(1, {
      message: "Rechnungsmail ist erforderlich",
    }),
    allowedJobsCount: z.coerce.number().min(1, {
      message: "Erlaubte Jobsanzahl ist erforderlich",
    }),
    iban: z.string().min(1, {
      message: "IBAN ist erforderlich",
    }),
    bic: z.string().min(1, {
      message: "BIC ist erforderlich",
    }),
    phone: z.string().min(1, {
      message: "Telefon ist erforderlich",
    }),
  });

  type FormSchema = z.infer<typeof formSchema>;

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: organisation.name,
      street: organisation.street,
      houseNumber: organisation.houseNumber,
      postCode: organisation.postCode,
      city: organisation.city,
      country: organisation.country,
      invoiceMail: organisation.invoiceMail,
      allowedJobsCount: organisation.allowedJobsCount,
      vatId: organisation.vatId,
      signatureDate: new Date(organisation.signatureDate).toISOString().split("T")[0],
      debitReference: organisation.debitReference,
      iban: organisation.iban,
      bic: organisation.bic,
      phone: organisation.phone,
    },
  });

  const onSubmit = async (data: FormSchema) => {
    const req: UpdateOrganizationRequestDto = {
      name: data.name,
      street: data.street,
      houseNumber: data.houseNumber,
      postCode: data.postCode,
      city: data.city,
      country: data.country,
      invoiceMail: data.invoiceMail,
      allowedJobsCount: data.allowedJobsCount,
      vatId: data.vatId,
      signatureDate: data.signatureDate,
      debitReference: data.debitReference,
      iban: data.iban,
      bic: data.bic,
      phone: data.phone,
    };

    await apiRequest(`organizations/internal/${organisationId}`, "PATCH", {
      body: req,
      toast: {
        toastText: "Organisation erfolgreich aktualisiert",
      },
    });
    setReload((prev) => !prev);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-7 h-full">
        <div className="flex flex-col gap-3">
          <div className="grid grid-cols-3 gap-3">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormLabel className="text-[16px] font-bold text-[#12282A]">Name</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      className="w-full h-10 px-3 text-[#12282A] border border-[#d1d5db] rounded-lg"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="allowedJobsCount"
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormLabel className="text-[16px] font-bold text-[#12282A]">Erlaubte Jobsanzahl</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      {...field}
                      className="w-full h-10 px-3 text-[#12282A] border border-[#d1d5db] rounded-lg"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="signatureDate"
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormLabel className="text-[16px] font-bold text-[#12282A]">Signaturdatum</FormLabel>
                  <FormControl>
                    <Input
                      type="date"
                      {...field}
                      className="w-full h-10 px-3 text-[#12282A] border border-[#d1d5db] rounded-lg"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="debitReference"
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormLabel className="text-[16px] font-bold text-[#12282A]">Debitreferenz</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      className="w-full h-10 px-3 text-[#12282A] border border-[#d1d5db] rounded-lg"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="vatId"
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormLabel className="text-[16px] font-bold text-[#12282A]">USt-IdNr.</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      className="w-full h-10 px-3 text-[#12282A] border border-[#d1d5db] rounded-lg"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="street"
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormLabel className="text-[16px] font-bold text-[#12282A]">Straße</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      className="w-full h-10 px-3 text-[#12282A] border border-[#d1d5db] rounded-lg"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="houseNumber"
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormLabel className="text-[16px] font-bold text-[#12282A]">Hausnummer</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      className="w-full h-10 px-3 text-[#12282A] border border-[#d1d5db] rounded-lg"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="postCode"
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormLabel className="text-[16px] font-bold text-[#12282A]">PLZ</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      className="w-full h-10 px-3 text-[#12282A] border border-[#d1d5db] rounded-lg"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="city"
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormLabel className="text-[16px] font-bold text-[#12282A]">Ort</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      className="w-full h-10 px-3 text-[#12282A] border border-[#d1d5db] rounded-lg"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="country"
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormLabel className="text-[16px] font-bold text-[#12282A]">Land</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      className="w-full h-10 px-3 text-[#12282A] border border-[#d1d5db] rounded-lg"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="invoiceMail"
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormLabel className="text-[16px] font-bold text-[#12282A]">Rechnungsmail</FormLabel>
                  <FormControl>
                    <Input
                      type="email"
                      {...field}
                      className="w-full h-10 px-3 text-[#12282A] border border-[#d1d5db] rounded-lg"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormLabel className="text-[16px] font-bold text-[#12282A]">Telefon</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      className="w-full h-10 px-3 text-[#12282A] border border-[#d1d5db] rounded-lg"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="iban"
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormLabel className="text-[16px] font-bold text-[#12282A]">IBAN</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      className="w-full h-10 px-3 text-[#12282A] border border-[#d1d5db] rounded-lg"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="bic"
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg">
                  <FormLabel className="text-[16px] font-bold text-[#12282A]">BIC</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      {...field}
                      className="w-full h-10 px-3 text-[#12282A] border border-[#d1d5db] rounded-lg"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              variant="default"
              className="text-white font-bold flex items-center gap-4 w-fit self-end"
            >
              <p>Speichern</p>
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};

const OrganisationUsers = ({
  organizationAdmin,
  organizationUsers,
  organisationId,
  setReload,
}: {
  organizationAdmin: InternalUserListResponseDto;
  organizationUsers: InternalUserListResponseDto[];
  organisationId: string;
  setReload: Dispatch<SetStateAction<boolean>>;
}) => {
  const [user, setUser] = useState<InternalUserResponseDto>();
  const { apiRequest } = useApiRequest();

  const fetchUser = async (id: string) => {
    const res = await apiRequest<InternalUserResponseDto>(`users/internal/${id}`, "GET");
    if (res.data) {
      setUser(res.data);
    }
  };

  return (
    <div>
      <div className="flex flex-col gap-4">
        {[organizationAdmin, ...organizationUsers].map((user, i) => (
          <div key={user._id} className="cursor-pointer" onClick={() => fetchUser(user._id)}>
            <div className="flex gap-2">
              <img
                src={user.profilePictureUrl ?? "/user-default.svg"}
                alt={user.firstName}
                width={54}
                height={54}
                className="rounded-full h-14 w-14 object-cover"
              />
              <div>
                <p className="text-[16px] font-bold text-[#12282A]">
                  {user.firstName} {user.lastName}
                  {i === 0 ? " - Admin" : ""}
                </p>
                <p className="text-[16px] text-[#888]">{user.mail}</p>
              </div>
            </div>
            <hr />
          </div>
        ))}
      </div>
      {user && (
        <UserEditorDialog
          user={user}
          setReload={setReload}
          onClose={() => setUser(undefined)}
          endpoint={`organizations/internal/${organisationId}/user/${user._id}`}
        />
      )}
    </div>
  );
};

export default OrganisationDetailParent;
