const TickIcon = ({ active, size = 16 }: { active: boolean; size?: number }) => {
  if (active) {
    return (
      <svg width={size} height={size} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6.47847" cy="6.47847" r="6.47847" fill="url(#paint0_linear_626_7764)" stroke="none" />
        <path
          d="M4.32031 6.80102L5.9027 9.2832L8.54001 4.31885"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_626_7764"
            x1="-1.34079e-07"
            y1="1.79958"
            x2="12.2371"
            y2="10.7975"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5FD5DE" />
            <stop offset={1} stopColor="#C0DE60" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg width={size} height={size} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.5" cy="6.5" r={6} stroke="#5D5D5D" />
      <path d="M4.16406 6.5L5.91719 9.25L8.83906 3.75" stroke="#5D5D5D" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default TickIcon;
