import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../ui/components/ui/button";
import { Form } from "../../../../ui/components/ui/form";
import PersonalFormItems from "../../../organisations/root/personal-form-items";
import { PersonalDataSchemaType, personalDataSchema } from "../../../organisations/root/form-types";

const OnboardingPersonalForm = () => {
  const navigate = useNavigate();

  const personalDataString = localStorage.getItem("selfCheckoutPersonalData");
  const personalData = personalDataString ? (JSON.parse(personalDataString) as PersonalDataSchemaType) : null;

  const form = useForm<PersonalDataSchemaType>({
    resolver: zodResolver(personalDataSchema),
    mode: "onChange",
    defaultValues: {
      firstName: personalData?.firstName || "",
      lastName: personalData?.lastName || "",
      phone: personalData?.phone || "",
      name: personalData?.name || "",
      country: personalData?.country || "",
      vatId: personalData?.vatId || "",
      street: personalData?.street || "",
      houseNumber: personalData?.houseNumber || "",
      postCode: personalData?.postCode || "",
      city: personalData?.city || "",
    },
  });

  const onSubmit = (data: PersonalDataSchemaType) => {
    // Store the data in localStorage for the next step
    localStorage.setItem("selfCheckoutPersonalData", JSON.stringify(data));
    // Navigate to the next step
    navigate("/self-checkout/step2");
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit, (e) => console.log(e))}
        className="flex flex-col gap-5 h-full justify-around"
      >
        <div className="flex flex-col gap-10 md:gap-16">
          <div>
            <h3 className="text-2xl font-semibold text-center">Deine persönlichen Daten</h3>
            <p className="text-center text-gray-400 text-xs">
              Fülle zuerst Deine Daten aus, um zum Checkout zu gelangen
            </p>
          </div>
          <PersonalFormItems form={form} />
        </div>
        <Button type="submit" className="w-full" disabled={!form.formState.isValid}>
          Nächster Schritt
        </Button>
      </form>
    </Form>
  );
};

export default OnboardingPersonalForm;
